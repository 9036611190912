import { useSelector } from 'react-redux';

import * as userAction from 'actions/user';
import { isCreatingApplicationUrlSelector } from 'selectors/user';
import { SCRATCHPAY_URL, isCordovaIOSApp } from 'utils/constants';
import { redirectToExternalUrl } from 'utils/routeHelper';
import { useAppDispatch } from 'redux-hooks';

const useApplicationUrlCreator = () => {
  const dispatch = useAppDispatch();
  const isCreatingApplicationUrl = useSelector(isCreatingApplicationUrlSelector);

  const createApplicationUrl = () => {
    if (isCordovaIOSApp) {
      redirectToExternalUrl(SCRATCHPAY_URL);
    } else {
      if (!isCreatingApplicationUrl) {
        dispatch(userAction.createApplicationUrl());
      }
    }
  };

  return { isCreatingApplicationUrl, createApplicationUrl };
};

export default useApplicationUrlCreator;
