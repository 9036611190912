import { useSelector } from 'react-redux';

import { List, ListItem } from '@mui/material';

import { usePaymentMethodFetcher } from 'hooks';
import { selectPaymentMethod } from 'actions/paymentMethod';
import { routes } from 'utils/routeHelper';
import * as loanSelectors from 'selectors/loan';
import * as paymentMethodSelectors from 'selectors/paymentMethod';

import { Header } from 'components/layouts';
import { Col, Fab, LoadingSpinner, PaymentMethodListItem } from 'components/widgets';

import './MyPaymentMethods.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux-hooks';

export const MyPaymentMethods = () => {
  usePaymentMethodFetcher();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isFetchingLoans = useSelector(loanSelectors.isFetchingSelector);
  const isFetchingMethods = useSelector(paymentMethodSelectors.isFetchingSelector);
  const isFetching = isFetchingLoans || isFetchingMethods;

  const loanCount = useSelector(loanSelectors.loanCountSelector);
  const paymentMethods = useSelector(paymentMethodSelectors.paymentMethodsSelector);

  const hasLoans = !!loanCount;
  const hasPaymentMethods = !!paymentMethods.length;
  const isAddPaymentMethodButtonDisabled = !hasLoans || isFetching;

  const handleOnAddPaymentMethod = () => navigate(routes.PAYMENT_METHOD_DETAILS);

  const handleOnClickPaymentMethodItem = (id: string) => {
    dispatch(selectPaymentMethod(id));
    navigate(routes.PAYMENT_METHOD_OVERVIEW);
  };

  const renderPaymentMethodList = () => {
    const isDisplayInstructionEmptyLoans = !isFetching && !hasLoans;
    if (isDisplayInstructionEmptyLoans) {
      return (
        <div styleName="no-data-wrapper">
          You don't have any Payment method yet. To add a payment method, first you must&nbsp;
          <a>add a Loan</a>.
        </div>
      );
    }

    const isDisplayInstructionEmptyMethods = !isFetching && !hasPaymentMethods;
    if (isDisplayInstructionEmptyMethods) {
      return (
        <div styleName="no-data-wrapper">
          You don't have any Payment method yet. This is where you will see the list of all the your Payment methods.
        </div>
      );
    }

    return (
      <List styleName="list methods">
        {paymentMethods.map((method) => (
          <ListItem
            button
            key={method.id}
            styleName="list-item method"
            onClick={() => handleOnClickPaymentMethodItem(method.id)}
          >
            <PaymentMethodListItem method={method} />
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Col xs={12} sm={6} md={6} styleName="wrapper">
      <Header />
      {isFetching && <LoadingSpinner />}
      {renderPaymentMethodList()}
      <Fab disabled={isAddPaymentMethodButtonDisabled} onClick={handleOnAddPaymentMethod}>
        Add a payment method
      </Fab>
    </Col>
  );
};

export default MyPaymentMethods;
