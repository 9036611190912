import {
  Phone as IconPhone,
  PrintOutlined as IconFax,
  MailOutlineOutlined as IconMail,
  RoomOutlined as IconMapPin,
} from '@mui/icons-material';

import { FormattedSupportContact } from 'utils/format';
import { SUPPORT_CONTACT } from 'utils/constants';

import { Col } from 'components/widgets';
import { Header } from 'components/layouts';

import './Contact.scss';

const Contact = () => (
  <Col xs={12} sm={6} md={6} styleName="wrapper">
    <Header />
    <div styleName="title">{"We'd love to hear from you!"}</div>
    <div styleName="sub-title">Send us feedback, complaint or suggestion of improvement</div>
    <div styleName="contact-info-wrapper">
      <div styleName="contact-info">
        <IconPhone styleName="icon" />
        <a styleName="info" href={FormattedSupportContact.PHONE_NUMBER_REF}>
          {FormattedSupportContact.PHONE_NUMBER}
        </a>
      </div>
      <div styleName="contact-info">
        <IconFax styleName="icon" />
        <a styleName="info" href={FormattedSupportContact.FAX_NUMBER_REF}>
          {FormattedSupportContact.FAX_NUMBER}
        </a>
      </div>
      <div styleName="contact-info">
        <IconMail styleName="icon" />
        <a styleName="info" href={FormattedSupportContact.EMAIL_REF}>
          {SUPPORT_CONTACT.EMAIL}
        </a>
      </div>
      <div styleName="contact-info">
        <IconMapPin styleName="icon" />
        <span styleName="info">{SUPPORT_CONTACT.ADDRESS}</span>
      </div>
    </div>
  </Col>
);

export default Contact;
